import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Campaign from "../components/CampaignForm"

import Steve from "../images/stevecook.png"
import MensHealth from "../images/menshealth.svg"
import Train from "../images/SVG/biggest-loser.svg"
import MuscleFitness from "../images/musclefitness.svg"
import BodyBuilding from "../images/bodybuilding.svg"
import MensFitness from "../images/mensfitness.svg"
import Triangle from "../images/hexangle.svg"
import Mov3 from "../images/SVG/mov3.svg"
import FitnessCulture from "../images/fc-logo.png"
import SEO from "../components/seo"
import Video from "../components/video"

const IndexPage = ({data}) => {

  const insta = data.allInstaNode.edges
  return (
    <Layout>
      <SEO title="Fitness Icon, Coach, Bodybuilder" image="/images/stevecook.png" description="From college athlete to bodybuilding to coach on the Biggest Loser, Steve Cook is here to motivate, inspire, and teach everyone about everything fitness." />
      <div className="paper py-20 px-5">
        <div className="flex container mx-auto mt-10 mb-10 rounded-sm shadow-2xl black-paper hextone" style={{minHeight: "60vh"}}>
          <div className="w-full flex flex-wrap justify-center items-stretch">
            <div className="w-full md:w-1/2 px-5 lg:px-10 pt-20 pb-10 md:py-10 flex flex-col justify-center">
              <h1 className="text-3xl md:text-4xl leading-none text-white">I'll help you Reach your fitness goals <br className="hidden lg:block" /> through education and inspiration.</h1>
              <ul className="text-white mt-4">
                <li>Get Exclusive Discounts</li>
                <li>Get Free Training and Guides</li>
                <li>Be the First to Get Insights to My Life</li>
              </ul>
              <Campaign campaign="492448630" cta="Become a VIP" />
            </div>
            <div className="w-full md:w-1/2 flex justify-end items-end">
              <img src={Steve} width="100%" />
            </div>
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <div className="flex flex-wrap items-center justify-between">
            <img src={MensHealth} width="100%" className="w-16 md:w-32 mr-4"/>
            <img src={Train} width="200%" className="w-16 md:w-40 mr-4"/>
            <img src={BodyBuilding} width="100%" className="w-12 md:w-24 mr-4"/>
            <img src={MensFitness} width="100%" className="w-16 md:w-32 mr-4"/>
            <img src={MuscleFitness} width="100%" className="w-12 md:w-32 mr-4 hidden md:block"/>
          </div>
        </div>
      </div>

      <div className="py-20 black-paper">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap items-center justify-center px-5">
            <div className="w-full md:w-1/2 text-white md:pr-10">
              <h2 className="text-4xl leading-none">Just a Kid from Idaho</h2>
              <p className="font-light mt-2">I’m lucky enough to have an incredible, fitness-oriented family and community. With their support I’ve been able to achieve some major wins and credentials in men’s fitness modeling and bodybuilding competitions.</p>

              <p className="font-light mt-2">With over 2.5 million Instagram followers and 1.2 million YouTube subscribers, my fitness, nutrition, and lifestyle content has been viewed over half a billion times and changed thousands of lives along the way.</p> 

              <p className="font-light mt-2">Now as a founder and co-owner of Fitness Culture, I strive to give back and help people reach their potential with the right knowledge, motivation, and training techniques. It is truly an honor to be part of anyone’s fitness journey.</p>
              <div className="mt-10">

                <Link to="/my-story" className="px-4 py-2 rounded-sm text-white text-xl" style={{background: "#9d895a"}}>Learn More About Steve</Link>
              </div>
            </div>      
            <div className="w-full md:w-1/2 flex flex-col justify-center text-white md:pl-10 mt-10 md:mt-0">
              <Video
                videoSrcURL="https://www.youtube.com/embed/bHJX6iYQJoM"
                videoTitle="Homecoming: Remember why you started | Steve Cook"
              />
            </div>      
          </div>
        </div>
      </div>

      <div className="pt-20 pb-10 paper px-5">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 text-center">
              <h2 className="text-4xl leading-none">My Goal is to inspire you to reach yours.</h2>
              <p className="font-light mt-2">Everyone, no matter where they are in their fitness journey, has the potential to reach their goals. With planning, organization, and drive, you too can see incredible results from applying these tried and tested 
              techniques to break through and elevate your health and fitness to new levels.</p>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center -mx-5">
            <div className="w-full md:w-1/3 px-5 mt-10">
              <div className="bg-white shadow rounded-sm p-5">
                <img src={Triangle} width="50px" className="mx-auto my-4" style={{transform: "rotate(180deg)"}} />
                <h3 className="text-center text-2xl">Follow Along</h3>
                <p className="mt-6 font-light">Follow along with me on my journey to get inspired and educated for yours. I share all my knowledge and give you an inside look into my life</p>
              </div>
            </div> 
            <div className="w-full md:w-1/3 px-5 mt-10">
              <div className="bg-white shadow rounded-sm p-5">
                <img src={Triangle} width="50px" className="mx-auto my-4" style={{transform: "rotate(-90deg)"}} />
                <h3 className="text-center text-2xl">Put In the Work</h3>
                <p className="mt-6 font-light">If not you then who? If not now then when? Be proactive and take action every single day striving to get better. A little progress is still progress.</p>
              </div>
            </div> 
            <div className="w-full md:w-1/3 px-5 mt-10">
              <div className="bg-white shadow rounded-sm p-5">
                <img src={Triangle} width="50px" className="mx-auto my-4" style={{transform: "rotate(90deg)"}} />
                <h3 className="text-center text-2xl">Reach Your Goals</h3>
                <p className="mt-6 font-light">With hard work and the right guidance you can reach your goal and when you do don't forget to share your successes and tag me so I can see your transformation.</p>
              </div>
            </div>           
          </div>

          <div className="flex flex-wrap items-center justify-center mt-10">
            <Link to="/vip" className="px-4 py-2 text-white bg-black rounded-sm text-xl">Become a VIP</Link>
          </div>

        </div>
      </div>


      <div className="paper py-10">
        <div className="flex flex-wrap justify-center items-stretch">
          <div className="w-full md:w-1/2 black-paper py-20 flex flex-col justify-center items-center">
            <img src={Mov3} width="50px" className="my-10 text-center" />
            <h3 className="text-4xl leading-none" style={{color: "#9d895a"}}>Lifestyle Training</h3> 
            <Link to="/mov3" className="text-white mt-6 text-xl px-4 py-2 border-2 border-white rounded-sm">Get Access to Mov3</Link>
          </div>
          <div className="w-full md:w-1/2 gray-paper py-20 flex flex-col justify-center items-center">
            <img src={FitnessCulture} width="150px" className="my-10 text-center" />
            <h3 className="text-4xl leading-none" style={{color: "#9d895a"}}>Advanced Training</h3> 
            <Link to="/fitness-culture" className="mt-6 text-xl px-4 py-2 border-2 border-black rounded-sm">Get My 7 Day Guide</Link>
          </div>
        </div>
      </div>
 



      <div className="py-10 paper px-5">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap justify-center -mx-5">
            {insta.map(({node, i}) => {
              return(
                <div key={i} className="w-1/2 md:w-1/4 px-5">
                  <div className="bg-white shadow mt-10">
                    <div className="w-full overflow-hidden inline-block h-32 md:h-48">
                      <Img
                        fluid={node.localFile.childImageSharp.fluid}
                        alt={node.optionName}
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>


      <div className="py-20 pb-2 paper px-5">
        <div className="container mx-auto mb-10">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 text-center">
              <h2 className="text-4xl leading-none">Follow Along and Become a VIP</h2>
              <p className="font-light mt-2">Get weekly education and inspiration straight to your inbox to help you reach your true potential.</p>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 flex justify-center">
              <Campaign campaign="492448630" cta="Become a VIP" />
            </div>
          </div>
        </div>
      </div>

{/* 
      <div className="flex flex-wrap items-center justify-center">
        <div className="w-full md:w-1/2 text-center h-48 flex flex-col justify-center gray-paper">
          <a href="https://fitnessculture.com" target="_blank" rel="noopener" className="text-4xl leading-none" style={{color: "#9d895a"}}>Workouts</a>
        </div>
        <div className="w-full md:w-1/2 text-center h-48 flex flex-col justify-center black-paper">
        <a href="https://stevecook.merchlabs.com" target="_blank" rel="noopener" className="text-4xl leading-none" style={{color: "#9d895a"}}>Apparel</a>
        </div>
      </div> */}

    </Layout>
  )
}
export default IndexPage

export const instaQuery = graphql`
query {
  allInstaNode {
    edges {
      node {
        id
        likes
        comments
        mediaType
        preview
        original
        timestamp
        caption
        localFile {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        # Only available with the public api scraper
        thumbnails {
          src
          config_width
          config_height
        }
        dimensions {
          height
          width
        }
      }
    }
  }
}
`